const supportedTelcos = {
	SI_SIMOBIL: 'SI_SIMOBIL',
	SI_TUSMOBIL: 'SI_TUSMOBIL',
	SI_MOBITEL: 'SI_MOBITEL',
	PL_PLAY: 'play',
	PL_ORANGE: 'orange',
	PL_TMOBILE: 't-mobile',
	PL_PLUS: 'plus',
	EE_TELIA: 'telia',
	EE_ELISA: 'elisa',
	EE_TELE2: 'tele2',
	EE_TEST: 'ee_test',
	LT_TELE2: 'lt_tele2',
	LT_TELIA: 'lt_telia',
	LT_BITE: 'lt_bite',
	LV_TELE2: 'lv_tele2',
	/** LMT */
	LV_TELIA: 'lv_telia',
	LV_BITE: 'lv_bite',
	FI_ELISA: 'fi_elisa',
	FI_TELIA: 'fi_telia',
	FI_DNA: 'FI_DNA',
	DE_TMOBILE: 'DE_TMOBILE',
	DE_O2: 'DE_O2',
	DE_VODAFONE: 'DE_VODAFONE'
      }


export default {
	'37200000010': {
	  carrier: supportedTelcos.EE_ELISA,
	  response: {
	    purchase: true,
	    subscribe: true
	  }
	},
	'37200000011': {
	  carrier: supportedTelcos.EE_TELIA,
	  response: {
	    purchase: true,
	    subscribe: true
	  }
	},
      
	'37200000012': {
	  carrier: supportedTelcos.EE_TELE2,
	  response: {
	    purchase: true,
	    subscribe: true
	  }
	},
      
	'37200000110': {
	  carrier: supportedTelcos.EE_ELISA,
	  response: {
	    purchase: false,
	    subscribe: false
	  }
	},
      
	'38600000010': {
	  carrier: supportedTelcos.SI_MOBITEL,
	  response: {
	    purchase: false,
	    subscribe: true
	  }
	},
	'38600000020': {
	  carrier: supportedTelcos.SI_TUSMOBIL,
	  response: {
	    purchase: false,
	    subscribe: true
	  }
	},
	'38600000021': {
	  carrier: supportedTelcos.SI_TUSMOBIL,
	  response: {
	    purchase: false,
	    subscribe: false
	  }
	},
	'38600000030': {
	  carrier: supportedTelcos.SI_SIMOBIL,
	  response: {
	    purchase: true,
	    subscribe: true
	  }
	},
	'38600000110': {
	  carrier: supportedTelcos.SI_MOBITEL,
	  response: {
	    purchase: false,
	    subscribe: true
	  }
	},
	'38600000210': {
	  carrier: supportedTelcos.SI_MOBITEL,
	  response: {
	    purchase: false,
	    subscribe: true
	  }
	},
	'38600000120': {
	  carrier: supportedTelcos.SI_TUSMOBIL,
	  response: {
	    purchase: false,
	    subscribe: true
	  }
	},
	'38600000220': {
	  carrier: supportedTelcos.SI_TUSMOBIL,
	  response: {
	    purchase: false,
	    subscribe: true
	  }
	},
	'38600000121': {
	  carrier: supportedTelcos.SI_TUSMOBIL,
	  response: {
	    purchase: false,
	    subscribe: false
	  }
	},
	'38600000130': {
	  carrier: supportedTelcos.SI_SIMOBIL,
	  response: {
	    purchase: true,
	    subscribe: true
	  }
	},
	// Kaakonviestinta actual telia number
	'358447059902': {
	  carrier: supportedTelcos.FI_ELISA,
	  response: {
	    purchase: true,
	    subscribe: true
	  }
	},
	// Lithuania
	'37000000010': {
	  carrier: supportedTelcos.LT_TELE2,
	  response: {
	    purchase: true,
	    subscribe: true
	  }
	},
	'37000000021': {
	  carrier: supportedTelcos.LT_BITE,
	  response: {
	    purchase: false,
	    subscribe: false
	  }
	},
	// Latvia
	'37100000010': {
	  carrier: supportedTelcos.LV_TELE2,
	  response: {
	    purchase: true,
	    subscribe: true
	  }
	},
	'37100000020': {
	  carrier: supportedTelcos.LV_BITE,
	  response: {
	    purchase: true,
	    subscribe: true
	  }
	},
	'37100000030': {
	  carrier: supportedTelcos.LV_TELIA,
	  response: {
	    purchase: true,
	    subscribe: true
	  }
	},
	'37100000021': {
	  carrier: supportedTelcos.LV_BITE,
	  response: {
	    purchase: false,
	    subscribe: false
	  }
	}
      }