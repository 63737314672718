import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Zoom from '@mui/material/Zoom'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types'
import * as React from 'react'
import themeData from './theme'
import { useFormik } from 'formik'
import Purchase from './purchase'
import Subscribe from './subscribe'
import { DEFAULT_AMOUNT, DEFAULT_CLIENT_TOKEN, DEFAULT_MESSAGE_STATE, DEFAULT_REFERENCE_NAME, DEFAULT_STATE, SIGN_SERVICE } from '../conf'
import InfoCardPurchase from './purchase/InfoCard'
import InfoCardSubscription from './subscribe/InfoCard'
import { active, hidden, tabLabel } from './styles'
import logo from './logo.svg'
import { getZlickSDK } from '../utils'
import { Alert, Snackbar } from '@mui/material'


const zlick = getZlickSDK()
const theme = createTheme(themeData)

function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired
}

function validatePayload(values) {
  const { token, amount, contentId } = values

  const purchasePayloadValid = (token && amount && contentId)
    && (token.length >= 32)
    && amount > 0
    && contentId.length

  return purchasePayloadValid
}

export default function App(props) {
  const [tabNumber, setTabNumber] = React.useState(0)
  const handleChange = (event, value) => {
    setTabNumber(value)
  }
  const tabLabels = ['purchase', 'subscription']
  const formik = useFormik({
    initialValues: {
      amount: DEFAULT_AMOUNT,
      token: DEFAULT_CLIENT_TOKEN,
      refundReason: 'testing refund',
      productName: 'toode2',
      phone: '',
      url: window.location.href.toString(),
      contentId: `p-${parseInt(Math.random() * 1000)}-${parseInt(Math.random() * 1000)}-${parseInt(Math.random() * 1000)}`,
      referenceName: DEFAULT_REFERENCE_NAME
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    }
  })

  const [showMessage, setShowMessage] = React.useState(DEFAULT_MESSAGE_STATE)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setShowMessage(DEFAULT_MESSAGE_STATE)
  }

  const [state, setState] = React.useState({ ...DEFAULT_STATE })

  const tabs = [
    <Purchase setShowMessage={setShowMessage} state={state} setState={setState} formik={formik} />,
    <Subscribe setShowMessage={setShowMessage} state={state} setState={setState} formik={formik} />
  ]


  React.useEffect(async () => {
    if (validatePayload(formik.values)) {
      const purchasePayload = ['amount', 'token', 'contentId', 'url', 'referenceName']
        .reduce((acc, x) => {
          acc[x] = formik.values[x]
          return acc
        }, {})

      const subscriptionPayload = ['productName', 'token', 'referenceId']
        .reduce((acc, x) => {
          acc[x] = formik.values[x]
          return acc
        }, {})

      setState({ ...state, purchasePayload })
      const signedPurchasePayload = await zlick.Axios.post(SIGN_SERVICE, { ...purchasePayload })
      const signedSubscriptionPayload = await zlick.Axios.post(SIGN_SERVICE, { ...subscriptionPayload })
      const identifyResponse = await zlick.identifyClient(signedPurchasePayload.data.jwtToken)
      const identifyResponseSubscription = await zlick.identifyClient(signedPurchasePayload.data.jwtToken)
      setState({ ...state, jwtToken: signedPurchasePayload.data.jwtToken, jwtTokenSubscription: signedSubscriptionPayload.data.jwtToken, identifyResponse, identifyResponseSubscription, purchasePayload, subscriptionPayload })
    }
  }, [...(Object.keys(formik.values).map(x => formik.values[x]))])

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <CssBaseline />
        <AppBar elevation={0}>
          <Toolbar>
            <Typography
              sx={{
                position: 'relative',
                paddingLeft: '34px'
              }}
              variant="h6"
              component="div">
              <img style={{ position: 'absolute', left: 0, top: '2px' }} src={logo}></img><b>SDK</b> Demo
            </Typography>
          </Toolbar>
        </AppBar>
        <Toolbar id="back-to-top-anchor" />
        <Grid container spacing={10} justify='space-evenly'>
          <Grid item xs={12}>
            <AppBar sx={{ 
              top: '64px',
              '@media screen and (max-width: 599px)': {
                top: '56px'
              },
            }} elevation={0} position='fixed' color='primary'>
              <Tabs indicatorColor='secondary' variant='scrollable' scrollButtons='on' value={tabNumber} onChange={handleChange}>{tabLabels.map(label => <Tab id={label} label={<Box component='span' sx={tabLabel}>{label}</Box>} />)}</Tabs>
            </AppBar>
            <Grid style={{ marginTop: 32 }} container spacing={4}>
              <Grid item md={7} xs={12}>
                <form onSubmit={formik.handleSubmit}>
                  <React.Fragment>{tabs.map((tab, index) => <Box component='div' sx={index === tabNumber ? active : hidden}>
                    <Container>
                      {tab}
                    </Container>
                  </Box>)}
                  </React.Fragment>
                </form>
              </Grid>
              <Grid item xs={12} md={4}>
                {tabNumber === 0 ? <InfoCardPurchase state={state} /> : <InfoCardSubscription state={state} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ScrollTop {...props}>
          <Fab color="primary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>


        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={showMessage.open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={showMessage.severity} sx={{ width: '100%' }}>
            {showMessage.text}
          </Alert>
        </Snackbar>

      </React.Fragment>
    </ThemeProvider>
  )
}
