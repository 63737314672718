import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import NativeSelect from '@mui/material/NativeSelect'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { DEFAULT_STATE } from '../../conf'
import { canSubscribe, canUnsubscribe, getZlickSDK, hasAccessRights, hasAccessRightsSubscription } from '../../utils'
import products from '../../utils/products'
import AuthStartInterface from '../auth/AuthStartInterface'
import PinCodeInterface from '../auth/PinCodeInterface'
import { SMS_SUCCESS } from '../auth/state'
import phones from '../../utils/phones'

const PRESET_PIN = '5544'

export default function Subscribe(props) {
  const { formik, state, setState, setShowMessage } = props
  const handleFillPhone = event => {
    formik.setFieldValue('phone', event.target.value)
    formik.setFieldValue('pin', PRESET_PIN)
  }

  const handleSetProduct = event => {
    formik.setFieldValue('product', event.target.value)
  }

  const zlick = getZlickSDK()
  const handleSubscribe = async () => {
    try {
      const subscriptionResponse = await zlick.subscribe({ token: state.jwtTokenSubscription, userId: (state.identifyResponseSubscription.userId || state.authVerificationResponse.userId), url: formik.values.url })
      setShowMessage({ text: 'Subscription successful', open: true, severity: 'success' })
      setState({ ...state, subscriptionResponse, allowedMethodsSubscription: subscriptionResponse.allowedMethods })
    } catch (error) {
      console.log(error)
      setShowMessage({ text: error.message, open: true, severity: 'error' })
      setState({ ...state, subscriptionResponse: { ...state.subscriptionResponse, error} })
    }
  }

  const handleUnSubscribe = async () => {
    const zlick = getZlickSDK()
    try {
      const{ subscriptionResponse, identifyResponse } = state
      const response = subscriptionResponse || identifyResponse
      const subscriptionId = response && response.subscriptionId
      if (!subscriptionId) {
        setShowMessage({ text: 'Something went wrong, cannot get subscriptionId to unsubscribe', open: true, severity: 'warning' })
        return
      }
      const unsubscribeResponse = await zlick.unsubscribe({ token: state.jwtTokenSubscription, subscriptionId })
      const identifyResponseSubscription = await zlick.identifyClient(state.jwtTokenSubscription)
      setShowMessage({ text: 'Unsubscribed', open: true, severity: 'success' })
      setState({ 
        ...state,
        unsubscribeResponse,
        identifyResponseSubscription,
        allowedMethodsSubscription: unsubscribeResponse ? unsubscribeResponse.allowedMethods: state.allowedMethods 
      })
    } catch (error) {
      console.log(error)
      setShowMessage({ text: error.message, open: true, severity: 'error' })
      setState({ ...state, unsubscribeResponse: error })
    }
  }

  const handleReset = () => {
    setState({ ...DEFAULT_STATE })
    formik.resetForm()
  }


  return (
    <div>
      <div style={{ marginBottom: 30 }}>
        <Typography variant='h6' fontWeight='bold'>Subscription test</Typography>
        <Typography variant='body2'>Subscription configuration</Typography>
      </div>
      <div style={{ marginBottom: 15 }}>
        <FormControl>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
            Prefill product
          </InputLabel>
          <NativeSelect
            defaultValue={30}
            inputProps={{
              name: 'productName',
              id: 'productName',
              value: formik.values.productName,
              error: formik.touched.productName && Boolean(formik.errors.productName),
              onChange: formik.handleChange
            }}
          >
            <option />
            {products.map(({ name, description }) => {
              return <option value={name}>{name} - {description}</option>
            })}
          </NativeSelect>
        </FormControl>
      </div>

      <TextField
        InputLabelProps={{ shrink: true }}
        variant='standard'
        id="url"
        name="url"
        label="URL"
        value={formik.values.url}
        onChange={formik.handleChange}
        error={formik.touched.url && Boolean(formik.errors.url)}
        helperText={formik.touched.url && formik.errors.url}
        style={{ width: 300, marginRight: 15, marginBottom: 15 }}
      />

      <TextField
        InputLabelProps={{ shrink: true }}
        variant='standard'
        id="token"
        name="token"
        label="Client token"
        value={formik.values.token}
        onChange={formik.handleChange}
        error={formik.touched.token && Boolean(formik.errors.token)}
        helperText={formik.touched.token && formik.errors.token}
        style={{ width: 300, marginRight: 15, marginBottom: 45 }}
      />

      <TextField
        InputLabelProps={{ shrink: true }}
        variant='standard'
        id="clientUserId"
        name="clientUserId"
        label="Client user ID (optional)"
        value={formik.values.token}
        onChange={formik.handleChange}
        error={formik.touched.clientUserId && Boolean(formik.errors.clientUserId)}
        helperText={formik.touched.clientUserId && formik.errors.clientUserId}
        style={{ width: 300, marginRight: 15, marginBottom: 45 }}
      />

      <div style={{ marginBottom: 30 }}>
        <Typography variant='body2'>Authentication</Typography>
      </div>

      <div style={{ marginBottom: 15 }}>
        <FormControl>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
            Autofill preset phone
          </InputLabel>
          <NativeSelect
            defaultValue={30}
            inputProps={{
              name: 'preset-phone',
              id: 'preset-phone',
            }}
            onChange={handleFillPhone}
          >
            <option />
            {Object.keys(phones).map(phone => {
              const numberInfo = phones[phone]
              return <option value={phone}>{phone} - {numberInfo.carrier} - {numberInfo.response.purchase ? 'success' : 'fail'}</option>
            })}
          </NativeSelect>
        </FormControl>
      </div>
      <AuthStartInterface type='subscription' {...props} />
      <PinCodeInterface type='subscription' {...props} />

      <div>
        <Button
          style={{ marginRight: 15, marginBottom: 15 }}
          variant='contained'
          color='primary'
          onClick={handleSubscribe}
          disabled={!canSubscribe(state)}>
          Subscribe
        </Button>
        <Button onClick={handleUnSubscribe} style={{ marginRight: 15, marginBottom: 15 }} variant='contained' color='primary' disabled={!canUnsubscribe(state)}>Unsubscribe</Button>
        <Button onClick={handleReset} style={{ marginRight: 15, marginBottom: 15 }} variant='contained' color='primary'>Reset</Button>
      </div>
    </div>
  )
}
