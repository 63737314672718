/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react'
import { infoCard, purchaseTitle } from "../styles"
import React from "react"
import Paper from "@mui/material/Paper"
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { hasAccessRights, hasAccessRightsSubscription } from '../../utils'
import quote from 'inspirational-quotes'

export default function (props) {
  const { state } = props
  const content = hasAccessRightsSubscription(state)
    ? (<div><b>Content subscirbed. Random quote:</b><br />{quote.getRandomQuote()}</div>)
    : (<div>Product not subscribed</div>)

  return (
    <Paper sx={infoCard} elevation={1}>
      <Typography sx={purchaseTitle}>SUBSCRIPTION INFO</Typography>
      <React.Fragment>
        <Box>
          {content}
        </Box>
        <pre>{JSON.stringify(state.subscriptionPayload, null, 2)}</pre>
        <b>Identify response</b>
        <pre>{JSON.stringify(state.identifyResponseSubscription, null, 2)}</pre>
        <b>Auth verification response</b>
        <pre>{JSON.stringify(state.authVerificationResponse, null, 2)}</pre>

        <b>Subscription response</b>
        <pre>{JSON.stringify(state.subscriptionResponse, null, 2)}</pre>

        <b>Unsubscribe response</b>
        <pre>{JSON.stringify(state.unsubscribeResponse, null, 2)}</pre>
        
        <b>Allowed methods</b>
        <pre>{JSON.stringify(state.allowedMethodsSubscription, null, 2)}</pre>

      </React.Fragment>
    </Paper>
  )
}