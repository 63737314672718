/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react'
import { infoCard, purchaseTitle } from "../styles"
import React from "react"
import Paper from "@mui/material/Paper"
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { hasAccessRights } from '../../utils'
import quote from 'inspirational-quotes'

export default function (props) {
  const { state } = props
  const content = hasAccessRights(state)
    ? (<div><b>Article purchased. Random quote:</b><br />{quote.getRandomQuote()}</div>)
    : (<div>Product not purchased</div>)

  return (
    <Paper sx={infoCard} elevation={1}>
      <Typography sx={purchaseTitle}>PURCHASE INFO</Typography>
      <React.Fragment>
        <Box>
          {content}
        </Box>
        <pre>{JSON.stringify(state.purchasePayload, null, 2)}</pre>
        <b>Identify response</b>
        <pre>{JSON.stringify(state.identifyResponse, null, 2)}</pre>
        <b>Auth verification response</b>
        <pre>{JSON.stringify(state.authVerificationResponse, null, 2)}</pre>

        <b>Purchase response</b>
        <pre>{JSON.stringify(state.purchaseResponse, null, 2)}</pre>

        <b>Refund response</b>
        <pre>{JSON.stringify(state.refundResponse, null, 2)}</pre>

      </React.Fragment>
    </Paper>
  )
}