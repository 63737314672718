import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import { getZlickSDK } from '../../utils'
import { SMS_VERIFY } from '../auth/state'

export default function AuthStartInterface(props) {
  const { formik, state, setState, setShowMessage, type } = props
  const { values } = formik
  const zlick = getZlickSDK()
  
  const handleSendPin = async () => {
    try {
      const response = await zlick.sendPinCodeSMS({ token: type === 'subscription' ? state.jwtTokenSubscription : state.jwtToken, mobilePhoneNumber: values.phone })
      setShowMessage({ text: 'SMS sent', open: true, severity: 'info'})
      setState({ ...state, authResponse: response, smsAuthState: response.authMethod === 'PINCODE_SMS' ? SMS_VERIFY : null })
    } catch(error) {
      setShowMessage({ text: error.message, open: true, severity: 'error'})
    }
  }

  return (
    <div style={{position: 'relative'}}>
      <TextField
        InputLabelProps={{ shrink: true }}
        variant='standard'
        id="phone"
        name="phone"
        label="Phone"
        value={formik.values.phone}
        onChange={formik.handleChange}
        error={formik.touched.phone && Boolean(formik.errors.phone)}
        helperText={formik.touched.phone && formik.errors.phone}
        style={{ width: 300, marginRight: 15, marginBottom: 45 }}
      />
      <Button style={{ position: 'absolute', left: 297, top: 12 }} variant='text' onClick={handleSendPin} disabled={!formik.values.phone}>SEND</Button>
    </div>
  )
}