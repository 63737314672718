import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import { getZlickSDK } from '../../utils'
import { SMS_RETRY, SMS_SUCCESS, SMS_VERIFY } from '../auth/state'

export default function PinCodeInterface(props) {
  const { formik, state, setState, setShowMessage, type } = props
  const zlick = getZlickSDK()

  if (![SMS_RETRY, SMS_VERIFY].includes(state.smsAuthState)) return null
  
  const handleVerifyPin = async () => {
    try {
      const authVerificationResponse = await zlick.verifyPinCode({ 
        token: type === 'subscription' ? state.jwtTokenSubscription : state.jwtToken, 
        confirmationCode: formik.values.pin, 
        challengeId: state.authResponse.challengeId 
      })
      setState({ ...state, smsAuthState: SMS_SUCCESS, authVerificationResponse, allowedMethodsPurchase:  authVerificationResponse.allowedMethods, allowedMethodsSubscription: authVerificationResponse.allowedMethods})
      setShowMessage({ text: 'SMS authentication success', open: true, severity: 'success'})
    } catch (error) {
      if (error.statusCode === 403) {
        setState({ ...state, smsAuthState: SMS_RETRY, authVerificationResponse: error })
        setShowMessage({ text: 'Wrong PIN, try again', open: true, severity: 'warning'})
        return
      }
      setState({ ...state, smsAuthState: null })
      setShowMessage({ text: 'SMS auth failed', open: true, severity: 'error'})
    }
  }
  return (
    <div style={{ position: 'relative'}}>
      <TextField
        InputLabelProps={{ shrink: true }}
        variant='standard'
        id="pin"
        name="pin"
        label="pin"
        value={formik.values.pin}
        onChange={formik.handleChange}
        error={formik.touched.pin && Boolean(formik.errors.pin)}
        helperText={formik.touched.pin && formik.errors.pin}
        style={{ width: 300, marginRight: 15, marginBottom: 45 }}
      />
      <Button variant='text' onClick={handleVerifyPin} style={{ position: 'absolute', left: 297, top: 12 }}>VERIFY</Button>
    </div>
  )
}