export function hasAccessRights(state) {
  const { identifyResponse, purchaseResponse } = state
  if (identifyResponse && identifyResponse.hasAccessRights) {
    return true
  }
  if (purchaseResponse && purchaseResponse.hasAccessRights) {
    return true
  }
  return false
}

export function hasAccessRightsSubscription(state) {
  const { identifyResponseSubscription, subscriptionResponse } = state
  if (identifyResponseSubscription && identifyResponseSubscription.hasAccessRights) {
    return true
  }
  if (subscriptionResponse && subscriptionResponse.hasAccessRights) {
    return true
  }
  return false
}

export function canSubscribe (state) {
  if (state.allowedMethodsSubscription.subscribe) {
    return true
  }
  return false
}

export function canUnsubscribe (state) {
  if (!state.subscriptionResponse) return false
  if (state.allowedMethodsSubscription.unsubscribe) {
    return true
  }
  return false
}

export function getZlickSDK() {
  return window.zlick
}