import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import NativeSelect from '@mui/material/NativeSelect'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { DEFAULT_STATE } from '../../conf'
import { getZlickSDK, hasAccessRights } from '../../utils'
import phones from '../../utils/phones'
import AuthStartInterface from '../auth/AuthStartInterface'
import PinCodeInterface from '../auth/PinCodeInterface'
import { SMS_SUCCESS } from '../auth/state'

const PRESET_PIN = '5544'

export default function Purchase(props) {
  const { formik, state, setState, setShowMessage } = props
  const handleFillPhone = event => {
    formik.setFieldValue('phone', event.target.value)
    formik.setFieldValue('pin', PRESET_PIN)
  }
  const zlick = getZlickSDK()
  const handlePurchase = async () => {
    try {
      const purchaseResponse = await zlick.purchase({ token: state.jwtToken, userId: (state.identifyResponse.userId || state.authVerificationResponse.userId), url: formik.values.url })
      setShowMessage({ text: 'Purchase success', open: true, severity: 'success' })
      setState({ ...state, purchaseResponse })
    } catch (error) {
      console.log(error)
      setShowMessage({ text: error.message, open: true, severity: 'error' })
      setState({ ...state, purchaseResponse: error })
    }
  }

  const handleRefund = async () => {
    const zlick = getZlickSDK()
    try {
      const userId = state.identifyResponse.userId || state.authVerificationResponse.userId
      const refundResponse = await zlick.refundPurchase({ token: state.jwtToken, userId, transactionId: state.purchaseResponse.transactionId, refundReason: formik.values.refundReason })
      const identifyResponse = await zlick.identifyClient(state.jwtToken)
      setShowMessage({ text: 'Refund success', open: true, severity: 'success' })
      setState({ ...state, refundResponse, identifyResponse, purchaseResponse: null })
    } catch (error) {
      console.log(error)
      setShowMessage({ text: error.message, open: true, severity: 'error' })
      setState({ ...state, refundResponse: error })
    }
  }

  const handleReset = () => {
    setState({ ...DEFAULT_STATE })
    formik.resetForm()
  }


  return (
    <div>
      <div style={{ marginBottom: 30 }}>
        <Typography variant='h6' fontWeight='bold'>Purchase test</Typography>
        <Typography variant='body2'>Article configuration</Typography>
      </div>
      <TextField
        type='number'
        InputLabelProps={{ shrink: true }}
        variant='standard'
        id="amount"
        name="amount"
        label="Amount in cents"
        value={formik.values.amount}
        onChange={formik.handleChange}
        error={formik.touched.amount && Boolean(formik.errors.amount)}
        helperText={formik.touched.amount && formik.errors.amount}
        style={{ width: 100, marginRight: 15, marginBottom: 15 }}
      />
      <TextField
        InputLabelProps={{ shrink: true }}
        variant='standard'
        id="contentId"
        name="contentId"
        label="Content Id"
        value={formik.values.contentId}
        onChange={formik.handleChange}
        error={formik.touched.contentId && Boolean(formik.errors.contentId)}
        helperText={formik.touched.contentId && formik.errors.contentId}
        style={{ width: 200, marginRight: 15, marginBottom: 15 }}
      />

      <TextField
        InputLabelProps={{ shrink: true }}
        variant='standard'
        id="referenceName"
        name="referenceName"
        label="Reference name"
        value={formik.values.referenceName}
        onChange={formik.handleChange}
        error={formik.touched.referenceName && Boolean(formik.errors.referenceName)}
        helperText={formik.touched.referenceName && formik.errors.referenceName}
        style={{ width: 200, marginRight: 15, marginBottom: 15 }}
      />

      <TextField
        InputLabelProps={{ shrink: true }}
        variant='standard'
        id="url"
        name="url"
        label="URL"
        value={formik.values.url}
        onChange={formik.handleChange}
        error={formik.touched.url && Boolean(formik.errors.url)}
        helperText={formik.touched.url && formik.errors.url}
        style={{ width: 300, marginRight: 15, marginBottom: 15 }}
      />

      <TextField
        InputLabelProps={{ shrink: true }}
        variant='standard'
        id="token"
        name="token"
        label="Client token"
        value={formik.values.token}
        onChange={formik.handleChange}
        error={formik.touched.token && Boolean(formik.errors.token)}
        helperText={formik.touched.token && formik.errors.token}
        style={{ width: 300, marginRight: 15, marginBottom: 45 }}
      />

      <div style={{ marginBottom: 30 }}>
        <Typography variant='body2'>Authentication</Typography>
      </div>

      <div style={{ marginBottom: 15 }}>
        <FormControl>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
            Autofill preset phone
          </InputLabel>
          <NativeSelect
            defaultValue={30}
            inputProps={{
              name: 'preset-phone',
              id: 'preset-phone',
            }}
            onChange={handleFillPhone}
          >
            <option />
            {Object.keys(phones).map(phone => {
              const numberInfo = phones[phone]
              return <option value={phone}>{phone} - {numberInfo.carrier} - {numberInfo.response.purchase ? 'success' : 'fail'}</option>
            })}
          </NativeSelect>
        </FormControl>
      </div>
      <AuthStartInterface {...props} />
      <PinCodeInterface {...props} />


      {hasAccessRights(state) && <TextField
        InputLabelProps={{ shrink: true }}
        variant='standard'
        id="refundReason"
        name="refundReason"
        label="Refund reason"
        value={formik.values.refundReason}
        onChange={formik.handleChange}
        error={formik.touched.refundReason && Boolean(formik.errors.refundReason)}
        helperText={formik.touched.refundReason && formik.errors.refundReason}
        style={{ width: 200, marginRight: 15, marginBottom: 45 }}
      />}
      <div>
        <Button
          style={{ marginRight: 15, marginBottom: 15 }} 
          variant='contained'
          color='primary'
          onClick={handlePurchase}
          disabled={!(state.smsAuthState === SMS_SUCCESS) || hasAccessRights(state)}>
          Purchase
        </Button>
        <Button onClick={handleRefund} style={{ marginRight: 15, marginBottom: 15 }} variant='contained' color='primary' disabled={!hasAccessRights(state)}>Refund</Button>
        <Button onClick={handleReset} style={{ marginRight: 15, marginBottom: 15 }} variant='contained' color='primary'>Reset</Button>
      </div>
    </div>
  )
}
