export default {

  typography: {
    fontFamily: [
      '-apple-system',
      'PT Sans',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },

  "palette": {
    "common": {
      "black": "#000",
      "white": "#fff"
    },
    "primary": {
      "main": "#1CB1F6",
      "light": "#42a5f5",
      "dark": "#1565c0",
      "contrastText": "#fff"
    },
    "secondary": {
      "light": "#ff4081",
      "main": "rgba(202, 84, 89, 1)",
      "dark": "#c51162",
      "contrastText": "#fff"
    },
  }
}