import { SMS_INITIAL } from "./components/auth/state"

export const SIGN_SERVICE = 'https://zdwrkwwy36.execute-api.eu-west-1.amazonaws.com/dev/sign'
export const DEFAULT_CLIENT_TOKEN = '7390d36701b0c29d8f8d3016af707ce9'
export const DEFAULT_AMOUNT = 100
export const DEFAULT_REFERENCE_NAME = 'REF1'
export const DEFAULT_STATE = {
	identifyResponse: null,
	identifyResponseSubscription: null,
	jwtToken: null,
	jwtTokenSubscription: null,
	purchaseResponse: null,
	refundResponse: null,
	subscriptionResponse: null,
	authResponse: null,
	authVerificationResponse: {},
	purchasePayload: null,
	subscriptionPayload: null,
	authMethod: null,
	smsAuthState: null,
	allowedMethodsPurchase: {},
	allowedMethodsSubscription: {}
}

export const DEFAULT_MESSAGE_STATE = { open: false, text: '', severity: 'info'}