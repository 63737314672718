export const active = {
  display: 'block'
}
export const hidden = {
  display: 'none'
}
export const tabBar = {
  top: 64
}
export const tabLabel = {
  fontSize: 10,
  color: theme => theme.palette.common.white
}
export const infoCard = {
  paddingTop: theme => theme.spacing(2),
  paddingBottom: theme => theme.spacing(4),
  marginBottom: theme => theme.spacing(4),
  paddingLeft: theme => theme.spacing(2),
  paddingRight: theme => theme.spacing(2),
  overflowY: 'scroll',
  fontSize: 10
}
export const purchaseTitle = {
  fontSize: 12,
  marginBottom: '5px',
  fontWeight: 'bold'
}

